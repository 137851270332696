<template>
<div>
  <div  class="taskContent" >
   <el-form ref="basicForm" :model="dataSource" :rules="baseCheckRule">
     <el-form-item>
        <el-col :span="3" class="form-title"><span style="color:red;">*</span>资源名称：</el-col>
        <el-col :span="9">
            <el-form-item prop="MenuName">
                <el-input v-model.trim="dataSource.MenuName"
                    :disabled="dataSource.ProductStatus==100" :maxlength="200" :minlength="4"
                   ></el-input>
            </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="3" class="form-title"><span style="color:red;">*</span>资源类型：</el-col>
        <el-col :span="9">
            <el-form-item prop="MenuBusinessType">
                <el-select v-model.trim="dataSource.MenuBusinessType">
                  <el-option 
                    v-for="item in businessTypeList"
                    :key="item.value" 
                    :value="item.value" 
                    :label="item.label" ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <template v-if="dataSource.MenuBusinessType==100">
          <el-col :span="3" class="form-title"><span style="color:red;">*</span>产品关联：</el-col>
          <el-col :span="9">
              <el-form-item prop="TenantProductName">
                  <LookPopup v-model="dataSource.TenantProductName"
                      dataTitle="业务产品"
                      dataUrl='omsapi/tenantproduct/getlist'
                      syncField='ChineseName'
                      @syncFieldEvent="syncMiddlewareProduct"></LookPopup>
              </el-form-item>
          </el-col>
        </template>
      </el-form-item>
      <el-form-item>
        <el-col :span="3" class="form-title"><span style="color:red;">*</span>业务类型：</el-col>
        <el-col :span="9">
            <el-form-item prop="PlatformType">
                <el-select v-model.trim="dataSource.PlatformType">
                  <el-option 
                    v-for="item in platformTypeList"
                    :key="item.value" 
                    :value="item.value" 
                    :label="item.label" ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :span="3" class="form-title"><span style="color:red;">*</span>父级：</el-col>
        <el-col :span="9">
            <el-form-item prop="ParentValues">
                <el-cascader
                    v-model="dataSource.ParentValues"
                    :options="treeList"
                    :props="{ checkStrictly: true,label:'MenuName',value:'SysMenuId',children:'Children' }"
                    clearable>
                </el-cascader>
            </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="3" class="form-title">是否外部链接：</el-col>
        <el-col :span="9">
            <el-form-item prop="IsExternal">
                <el-switch v-model.trim="dataSource.IsExternal"
												   active-color="#13ce66" inactive-color="#ff4949">
								</el-switch>
            </el-form-item>
        </el-col>
        <el-col :span="3" class="form-title">是否显示：</el-col>
        <el-col :span="9">
            <el-form-item prop="IsDisplayOnLeftPart">
                <el-switch v-model.trim="dataSource.IsDisplayOnLeftPart"
												   active-color="#13ce66" inactive-color="#ff4949">
								</el-switch>
            </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="3" class="form-title">资源编码：</el-col>
        <el-col :span="9">
            <el-form-item prop="MenuCode">
                <el-input v-model.trim="dataSource.MenuCode" :maxlength="30" :minlength="4" :disabled="true" ></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="3" class="form-title">资源链接：</el-col>
        <el-col :span="9">
            <el-form-item prop="MenuUrl">
                <el-input v-model.trim="dataSource.MenuUrl" :maxlength="200" :minlength="4"></el-input>
            </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        
         <el-col :span="3" class="form-title"><span style="color:red;"></span>资源图标：</el-col>
        <el-col :span="9">
            <el-form-item prop="MenuIconUrl">
                <el-input v-model.trim="dataSource.MenuIconUrl" :maxlength="200" :minlength="4" ></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="3" class="form-title"><span style="color:red;">*</span>资源序号：</el-col>
        <el-col :span="9">
            <el-form-item prop="MenuOrder">
                <el-input v-model="dataSource.MenuOrder" :maxlength="4" :minlength="1"></el-input>
            </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        
         <el-col :span="3" class="form-title"><span style="color:red;"></span>组件路径：</el-col>
        <el-col :span="9">
            <el-form-item prop="Component">
                <el-input v-model.trim="dataSource.Component" :maxlength="200" :minlength="4" ></el-input>
            </el-form-item>
        </el-col>
        <!--<el-col :span="3" class="form-title">请求路径：</el-col>
        <el-col :span="9">
            <el-form-item prop="Url">
                <el-input v-model="dataSource.Url" :maxlength="500" :minlength="1"></el-input>
            </el-form-item>
        </el-col>-->
      </el-form-item>
       
        <!--<el-form-item>
        <el-col :span="3" class="form-title">是否公用：</el-col>
        <el-col :span="9">
            <el-form-item prop="IsExternal">
                <el-switch v-model.trim="dataSource.IsPublic"
												   active-color="#13ce66" inactive-color="#ff4949">
								</el-switch>
            </el-form-item>
        </el-col>
        <el-col :span="3" class="form-title">是否承运商：</el-col>
        <el-col :span="9">
            <el-form-item prop="IsDisplayOnLeftPart">
                <el-switch v-model.trim="dataSource.CarrierRight"
												   active-color="#13ce66" inactive-color="#ff4949">
								</el-switch>
            </el-form-item>
        </el-col>
      </el-form-item>-->
      <el-form-item>
          <el-col :span="3" class="form-title">备注：</el-col>
          <el-col :span="21">
              <el-form-item prop="MenuDesc">
                  <el-input type="textarea" maxlength="50" show-word-limit
                      :autosize="{ minRows: 4, maxRows: 4}" v-model.trim="dataSource.MenuDesc">
                  </el-input>
              </el-form-item>
          </el-col>
      </el-form-item>
    </el-form>
</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      treeList:[],
      businessTypeList: [
        { label: '应用', value: 100 },
        { label: '菜单', value: 200 }
      ],
      platformTypeList: [
        { label: '平台管理', value: 1 },
        { label: '租户端', value: 2 }
      ],
      baseCheckRule: {
          MenuName:[{ required: true, message: '不能为空', trigger: 'blur' }],
          MenuBusinessType: [{ required: true, message: '不能为空', trigger: 'blur' }],
          MenuOrder: [{ required: true, message: '不能为空', trigger: 'blur' }],
          TenantProductName:[{ required: true, message: '不能为空', trigger: 'blur' }],
          PlatformType: [{ required: true, message: '不能为空', trigger: 'change' }],
          ParentValues: [{ required: true, message: '不能为空', trigger: 'blur' }],
          
      },
    }
  },
  props: {
    config: {
      isDetailDisplay: false,
    },
    dataSource: {}
  },
  mounted() {
    this.getTreeselect();
  },
  watch: {
    dataSource: {
      handler(curVal, oldVal) {
      },
      deep: true
    }
  },
  methods: {
    saveMenu:function(option){
      var _this=this;
      _this.$refs["basicForm"].validate((valid) => {
        if (valid) {
          _this.$ajax.send("omsapi/sysmenu/save", "post", this.dataSource, (data) => {
            _this.Event.$emit("reloadPageOrgList");
            _this.Utils.messageBox("保存成功.", "success");
            option.visible=false;
          });
        } else {
            option.visible=true;
        }
      });
    },
    getTreeselect() {
      	var _this = this;
			  this.$ajax.send('omsapi/sysmenu/getrecursionlist', 'get', {platformType:2}, data => {
				this.treeList = data.Result;

			});
		},
    syncMiddlewareProduct(item){
      if(item){
        this.dataSource.TenantProductId = item.Id;
      }
    }
  },

}
</script>

<style>
.taskContent .el-form-item__error {
  margin-bottom: 0px !important;
}
.taskContent .el-form-item {
  margin-bottom: 10px !important;
}
.el-form-item__content .el-cascader{ width: 100%;}
</style>
